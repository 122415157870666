@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
    font-weight: bold;

}


.shake-effect {
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
      border: 1px solid red;
    }
    25%, 75% {
      transform: translateX(-10px);
      border: 1px solid red;
    }
    50% {
      transform: translateX(10px);
      border: none;
    }
  }


.shake-effect#invalid  {
    animation: shake 0.5s ease-in-out;
    outline: 2px solid red;
    outline-offset: -3px;
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
      border: 1px solid red;
    }
    25%, 75% {
      transform: translateX(-10px);
      border: 1px solid red;
    }
    50% {
      transform: translateX(10px);
      border: none;
    }
  }
